import { ref } from "vue";

const userEmail = ref(null)
const usergroupType = ref(null)
const usergroupName = ref(null)
const userType = ref(null)
const chargebeeCustomerId = ref(null)
const provisioningKey = ref(null)
const impersonated = ref(null)
const serviceCDREnabled = ref(null)
const serviceMalwareEnabled = ref(null)
const servicePhishingEnabled = ref(null)
const serviceSpearPhishingEnabled = ref(null)

export default function useGlobalData(){

    function setUserEmail(val){
        userEmail.value=val;
    }

    function setUsergroupType(val){
        usergroupType.value=val;
    }

    function setUsergroupName(val){
        usergroupName.value=val;
    }

    function setUserType(val){
        userType.value=val;
    }

    function setChargebeeCustomerId(val){
        chargebeeCustomerId.value=val;
    }

    function setProvisioningKey(val){
        provisioningKey.value=val;
    }

    function setImpersonated(val){
        impersonated.value=val;
    }

    function setServiceCDREnabled(val){
        serviceCDREnabled.value=val;
    }

    function setServiceMalwareEnabled(val){
        serviceMalwareEnabled.value=val;
    }

    function setServicePhishingEnabled(val){
        servicePhishingEnabled.value=val;
    }

    function setServiceSpearPhishingEnabled(val){
        serviceSpearPhishingEnabled.value=val;
    }

    return {userEmail, setUserEmail, usergroupType, setUsergroupType, usergroupName, setUsergroupName, userType, setUserType, chargebeeCustomerId, setChargebeeCustomerId, provisioningKey, setProvisioningKey, impersonated, setImpersonated, serviceMalwareEnabled, setServiceMalwareEnabled, servicePhishingEnabled, setServicePhishingEnabled, serviceSpearPhishingEnabled, setServiceSpearPhishingEnabled, serviceCDREnabled, setServiceCDREnabled}
}
